import React from "react"
import ReactDOM from "react-dom/client"
import { Provider } from "react-redux"

import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"

import "react-quill/dist/quill.snow.css"

import "./index.scss"

import { loadCleverTapScript } from "./data/utils"
import * as serviceWorkerRegistration from "./serviceWorkerRegistration"
import reportWebVitals from "./reportWebVitals"
import store from "./data/store"

import App from "./App"
import * as Sentry from "@sentry/react";

const root = ReactDOM.createRoot(
  document.getElementById("saas-dashboard")
)

//========================= ADD ORIMON CHATBOT ==========================

const addOrimonChatbotInDashboard = () => {
  const script = document.createElement("script")
  script.src = process.env.REACT_APP_WEBSITE_CHATBOT_SCRIPT_SRC
  script.setAttribute(
    "tenantId",
    process.env.REACT_APP_WEBSITE_CHATBOT_TENANT_ID
  )
  document.body.appendChild(script)
}
if (
  process.env.REACT_APP_WEBSITE_CHATBOT_SCRIPT_SRC &&
  process.env.REACT_APP_WEBSITE_CHATBOT_TENANT_ID
)
  // addOrimonChatbotInDashboard()

//================================= END =================================
if(process.env.REACT_APP_SENTRY_DSN)
  Sentry.init({
    release: process.env.REACT_APP_VERSION,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.Replay(),
    ],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });

root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA

if (
  process.env.REACT_APP_CLEVERTAP_ENABLE &&
  process.env.REACT_APP_CLEVERTAP_TOKEN
) {
  let src =
    ("https:" === document.location.protocol
      ? "https://d2r1yp2w7bby2u.cloudfront.net"
      : "http://static.clevertap.com") + "/js/a.js"
  loadCleverTapScript(src, process.env.REACT_APP_CLEVERTAP_TOKEN)
}

serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (
      registration &&
      registration.waiting &&
      window.confirm("New version available!  refresh to update your app?")
    ) {
      registration.waiting.postMessage({ type: "SKIP_WAITING" })
      window.location.reload(true)
    }
  },
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
